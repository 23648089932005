import { datadogRum } from '@datadog/browser-rum-slim';
import { enableBrowserTelemetryInstrumentation } from '@gonfalon/constants';
import { captureError } from '@launchdarkly/browser-telemetry';

import { trackDogfoodError } from './trackDogfoodError';
import { RUMCustomErrorContext } from './types';

export function trackError(error: Error, { isCrash, severity, url }: RUMCustomErrorContext = {}) {
  datadogRum.addError(error, { isCrash, severity, url });
  if ((enableBrowserTelemetryInstrumentation() ?? false) && process.env.NODE_ENV === 'production') {
    captureError(error);
  }
  trackDogfoodError({ isCrash, severity });
}
