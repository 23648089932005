import { datadogRum } from '@datadog/browser-rum-slim';
import {
  accountId,
  backendVersion,
  datadogEnvironment,
  datadogRUMApplicationId,
  datadogRUMClientToken,
  datadogSite,
  frontendVersion,
  userId,
} from '@gonfalon/constants';
import { getDogfoodClient } from '@gonfalon/dogfood-client';
import {
  datadogRUMPremiumSampleRate,
  datadogRUMSampleRate,
  enableDataDogRumInteractions,
  enableDataDogRumResourceTracking,
  enableFullStoryLinksInDataDog,
  enableManuallyTrackedDdrumViews,
  frontendDatadogFlagRumTracking,
  isDatadogRUMAppEnabled,
  isKloudfuseRumEnabled,
  shouldTrackWebVitals,
} from '@gonfalon/dogfood-flags';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import kfuseRumSDK from 'kf-browser-sdk';
import {
  CLSMetricWithAttribution,
  FIDMetricWithAttribution,
  LCPMetricWithAttribution,
  onCLS,
  onFID,
  onLCP,
} from 'web-vitals/attribution';

import { trackDogfoodError } from './trackDogfoodError';
import { trackError } from './trackError';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __bundle__: string;
    _DATADOG_SYNTHETICS_BROWSER?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress?: any;
    FS: {
      getCurrentSessionURL(now?: boolean): string;
      setVars(scope: string, vars: { [key: string]: unknown }): void;
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCypressTest = () => window.Cypress !== undefined;

function initializeKloudfuseRum() {
  if (!isKloudfuseRumEnabled()) {
    return;
  }
  kfuseRumSDK.init({
    config: {
      applicationId: 'cf9939cf-682b-4b95-836e-7b96a9734423',
      clientToken: 'notneeded',
      proxy: 'https://launchdarkly-poc.kloudfuse.io/ddrumproxy',
      service: 'gonfalon',
      env: 'staging', //this will only be on in staging
      version: '1.0.0',
      sessionSampleRate: 100,
      defaultPrivacyLevel: 'mask',
      enableSessionRecording: true,
      enableLogCollection: true,
    },
  });
}

function initializeDatadogRum() {
  if (!isDatadogRUMAppEnabled()) {
    return;
  }

  datadogRum.init({
    applicationId: datadogRUMApplicationId(),
    clientToken: datadogRUMClientToken(),
    sessionSampleRate: datadogRUMSampleRate(),
    sessionReplaySampleRate: datadogRUMPremiumSampleRate(),
    env: datadogEnvironment(),
    version: frontendVersion(),
    site: datadogSite(),
    trackUserInteractions: enableDataDogRumInteractions(),
    trackResources: enableDataDogRumResourceTracking(),
    service: 'gonfalon',
    trackViewsManually: enableManuallyTrackedDdrumViews(),
    enableExperimentalFeatures: frontendDatadogFlagRumTracking() ? ['feature_flags'] : undefined,
    beforeSend: (event, context) => {
      if (
        event.type === 'error' &&
        window.FS !== undefined &&
        typeof window.FS.getCurrentSessionURL === 'function' &&
        enableFullStoryLinksInDataDog()
      ) {
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-param-reassign
        event.context = { ...event.context, FS_url_at_exception: window.FS.getCurrentSessionURL(true) };
      }
      if (event.type === 'resource') {
        let resourceData: {
          encodedBodySize?: PerformanceResourceTiming['encodedBodySize'];
          transferSize?: PerformanceResourceTiming['transferSize'];
          renderBlockingStatus?: string;
          cacheMode?: 'local' | 'validated';
          name?: string;
        } = {};
        try {
          if ('performanceEntry' in context) {
            if (context.performanceEntry instanceof PerformanceResourceTiming) {
              const { transferSize, encodedBodySize } = context.performanceEntry;
              const renderBlockingStatus =
                'renderBlockingStatus' in context.performanceEntry
                  ? (context.performanceEntry.renderBlockingStatus as string)
                  : undefined;
              // this copies the cache mode logic in https://www.w3.org/TR/resource-timing/#dfn-cache-mode
              let cacheMode: 'local' | 'validated' | undefined;
              if (transferSize === 0) {
                cacheMode = 'local';
              } else if (transferSize === 300) {
                cacheMode = 'validated';
              }
              resourceData = { cacheMode, transferSize, encodedBodySize, renderBlockingStatus };
            }
          }
          if (['css', 'js'].some((type) => type === event.resource.type)) {
            const regex =
              process.env.NODE_ENV === 'production'
                ? /\/s\/ld\/([^/]+?)\.[0-9a-fA-F]+\.(js|css)$/
                : /\/s\/ld\/([^/]+\.(css|js))$/;
            const match = event.resource.url.match(regex);

            if (match) {
              const endOfPath = process.env.NODE_ENV === 'production' ? `${match[1]}.${match[2]}` : match[1];
              resourceData.name = endOfPath;
            }
          }
        } catch (e) {
          //noop
        }
        if (Object.keys(resourceData).length > 0) {
          // eslint-disable-next-line no-param-reassign
          event.context = { ...event.context, resource: resourceData };
        }
      }
      return true;
    },
  });

  datadogRum.setGlobalContextProperty('entrypoint', window.__bundle__);
  datadogRum.setGlobalContextProperty('account', { id: accountId() });
  datadogRum.setGlobalContextProperty('user', { id: userId() });
  datadogRum.setGlobalContextProperty('backendVersion', backendVersion());
  if (enableManuallyTrackedDdrumViews()) {
    //we are manually tracking views so we need to start tracking the view as soon as dd is initialized.
    datadogRum.startView();
  }
}

function initializeDogfood() {
  window.addEventListener('error', () => trackDogfoodError());
  window.addEventListener('unhandledrejection', () => trackDogfoodError());
}

const webVitalsMeasureNames = {
  CLS: 'cumulative_layout_shift',
  FID: 'first_input_delay',
  LCP: 'largest_contentful_paint',
};

function trackWebVitals(metric: CLSMetricWithAttribution | FIDMetricWithAttribution | LCPMetricWithAttribution) {
  const context = {
    custom: {
      measures: {
        [webVitalsMeasureNames[metric.name]]: metric.value,
      },
      attribution: metric.attribution,
    },
  };

  datadogRum.addAction(metric.name, context);

  if (shouldTrackWebVitals().includes(metric.name)) {
    getDogfoodClient().track(`Gonfalon frontend ${metric.name}`, undefined, metric.value);
  }
}

function initializeWebVitals() {
  onCLS(trackWebVitals);
  onFID(trackWebVitals);
  onLCP(trackWebVitals);
}

/**
 * Initializes Datadog, and Catamorphic telemetry providers.
 * @param exceptions Array of exceptions that were caught before telemetry was initialized. These will be reported to the initialized providers.
 */
export function initializeTelemetry(exceptions: Error[]) {
  if (isCypressTest()) {
    return;
  }

  initializeDatadogRum();
  initializeKloudfuseRum();
  initializeDogfood();
  initializeWebVitals();

  exceptions.forEach((error) => trackError(error));
}
